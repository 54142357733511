class LocalizationEn
{
    ///// Home  Page /////
    layout_landing_label_welcome = "Welcome";
    layout_landing_label_eventname = "to Tecno Mueble Internacional";
    layout_landing_label_envenname_year = "2019 Register";
    layout_landing_button_register =  "Register";
    layout_landing_button_login =  "Log in";
    
    ///// Login  Page /////
    layout_login_label_backtohome = "Back to Home";
    layout_login_label_login =  "Log in";
    layout_login_label_noaccount = "Don't have an account?"
    layout_login_link_createaccount = "Register";
    layout_login_link_forgotpassword = "Forgot your password?";
    layout_login_label_email = "Email";
    layout_login_label_password = "Password";
    layout_login_button_login = "Log in";

    /// Register Page ////
    auth_register_label_backtohome = "Back to Home";
    auth_register_label_register = "Register";
    auth_register_label_alreadyAccount = "Already have an account? ";
    auth_register_label_login = "Log in";
    auth_register_label_name = "Name";
    auth_register_label_email = "Email";
    auth_register_label_emailconfirmation = "Confirm Email";
    auth_register_label_password = "Password";
    auth_register_label_passwordconfirmation = "Confirm Password ";
    auth_register_button_register = "Register";

    /// Account Activation ////
    auth_activation_label_hello = "Hello";    
    auth_activation_label_message_success = " your account was activated successfully ";
    auth_activation_label_message_failure = " your account was not activated successfully, try later please";
    auth_activation_label_button_login = "Log in";

    /// Email Activation  Message ////
    auth_emailactivation_label_thanks = "Thanks for registering to Tecno Mueble Internacional";
    auth_emailactivation_label_email_sent = "An email was sent to your account, please check your inbox and follow the steps described to complete your registration.";    
    auth_emailactivation_label_email_not_received = "You did not receive the verification email from Tecno Mueble Internacional? Check the spam folder or click on the following link.";
    auth_emailactivation_button_resend = "Resend";
    auth_emailactivation_button_login = "Log in";

    /// Student Register ////
    auth_student_label_formtitle = "Student Registration";    
    auth_student_label_nombre = "*Name";
    auth_student_label_last_name ="*Last Name";
    auth_student_label_email = "*Email";
    auth_student_label_phone = "*Phone";
    auth_student_label_university = "*University";
    auth_student_label_career = "*Career";
    auth_student_button_register = "Register";

    /// Landing Registry Success ////
    auth_landingsuccess_label_app_name = "Tecno Mueble";
    auth_landingsuccess_label_hello = "Hello ";
    auth_landingsuccess_label_message_success = " your registration was successfully completed ";
    auth_landingsuccess_label_message_sent = "A confirmation email was sent to ";
    auth_landingsuccess_label_message_note = "To enter the event, it is necessary to present that email in register (printed or digital) along with an official identification to obtain your badge."

    /// Password Reset ////
    auth_passwordreset_label_backtohome = "Back to Home";
    auth_passwordreset_label_formtitle = "Restore password";
    auth_passwordreset_label_usuario = "User";
    auth_passwordreset_label_email = "Email";
    auth_passwordreset_label_password = "Password";    
    auth_passwordreset_label_passwordconfirmation = "Confirm Password";  
    auth_passwordreset_button_confirmation = "Accept";     
    
    /// Password Reset Email Sent ////
    auth_passwordresetemail_label_backtohome = "Back to Home";
    auth_passwordresetemail_label_formtitle = "Restore password";
    auth_passwordresetemail_label_message = "To reset your password, enter your email address and press the button below. Follow the instructions included in the email you will receive.";
    auth_passwordresetemail_label_email = "Email";    
    auth_passwordreset_button_confirmation = "Send email";      

    /// Visitante ///
    auth_visitante_label_formtitle = "Guest Registration";
    auth_visitante_label_name = "*Name";
    auth_visitante_label_lastname ="*Last Name";
    auth_visitante_label_email ="*Email";
    auth_visitante_label_companyname = "*Company Name";    

    /// Comprador Landing Success ///
    dashboard_compradorlanding_label_hello = "Hello ";
    dashboard_compradorlanding_label_message_success = " your registration was successfully completed ";
    dashboard_compradorlanding_label_message_sent = "A confirmation email was sent to ";
    dashboard_compradorlanding_label_spam = " Do not forget to check your spam folder.";
    dashboard_compradorlanding_label_message_note = "To enter the event, it is necessary to present that email in register (printed or digital) along with an official identification to obtain your badge."
    dashboard_compradorlanding_label_update_success = " Your data was updated successfully"; 
    dashboard_compradorlanding_button_home = "Home";
    dashboard_compradorlanding_toast_success = "Email sent";
    dashboard_compradorlanding_toast_failure = "Email was not sent, try again later please";

    /// Home ////
    dashboard_dashboard_label_hello = "Hello ";
    dashboard_dashboard_label_message_success = "You have successfully logged in";
    dashboard_dashboard_label_message_registryconfirmation = "You are already registered in Tecno Mueble";
    dashboard_dashboard_button_updatedataregister = "Modify Registration";
    dashboard_dashboard_button_resendinvitation = "Resend invitation";
    dashboard_dashboard_button_logout = "Log out";
    dashboard_dashboard_button_register = "Register";

    ///// Comprador  Page /////
    dashboard_comprador_label_backtohome =  "Back to Home";
    dashboard_comprador_label_personalinformation = "Personal Information";
    dashboard_comprador_label_yearofbirth = "Year of Birth";
    dashboard_comprador_label_sex = "Gender";
    dashboard_comprador_label_female = "Female";
    dashboard_comprador_label_male = "Male";
    dashboard_comprador_label_name = "*First Name";
    dashboard_comprador_label_lastname = "*Last Name";
    dashboard_comprador_label_mail = "*Email";
    dashboard_comprador_label_cellphone = "Mobile";
    dashboard_comprador_label_phone = "*Phone";
    dashboard_comprador_label_address = "Address";
    dashboard_comprador_label_neighbordhood = "Neighborhood/County";
    dashboard_comprador_label_city = "*City";
    dashboard_comprador_label_zipcode = "Post Code";
    dashboard_comprador_label_state = "*State";
    dashboard_comprador_label_country = "*Country";
    dashboard_comprador_label_companyinformation = "*Company Information";
    dashboard_comprador_label_nameofcompany = "*Name of Company";
    dashboard_comprador_label_businessname = "*Business Name";
    dashboard_comprador_label_companyposition = "*Position within the Company";
    dashboard_comprador_label_rfc = "*RFC";
    dashboard_comprador_label_companyemail = "Company Email";
    dashboard_comprador_label_companywebpage = "Web Page";
    dashboard_comprador_label_business = "*Company Business";

    dashboard_comprador_label_furniture = "Furniture Store";
    dashboard_comprador_label_deparmentalstore = "Departmental Store";
    dashboard_comprador_label_decorationstore = "Decoration Store";
    dashboard_comprador_label_wholesale = "Wholesale (Includes Broker)";
    dashboard_comprador_label_construction = "Construction"
    dashboard_comprador_label_restaurantbar = "Restaurant / Bar";
    dashboard_comprador_label_hotel = "Hotel";    
    dashboard_comprador_label_decorationServices = "Interior Design Architectural Services";
    
    dashboard_comprador_label_furniture_manufacturer = "Furniture Manufacturer";
    dashboard_comprador_label_seller = "Seller";
    dashboard_comprador_label_designer = "Designer";
    dashboard_comprador_label_architect_consultant = "Architect/Consultant";
    dashboard_comprador_label_wood_seller = "Wood and Board Selling";
    dashboard_comprador_label_upholster_carpenter = "Upholsterer/Carpenter";

    dashboard_comprador_label_interest = "*Products you are interested in";
    dashboard_comprador_label_accesories = "Accesories";
    dashboard_comprador_label_entertaimentcenters = "Entertainment Centers / Bookcases";
    dashboard_comprador_label_mattresses = "Mattresses";
    dashboard_comprador_label_dinning = "Dinning";
    dashboard_comprador_label_children = "Children and Youth Furniture";
    dashboard_comprador_label_office = "Office Furniture";
    dashboard_comprador_label_bedroom = "Bedroom Furniture / Bunk Bed";
    dashboard_comprador_label_services = "Services";
    dashboard_comprador_label_upholstery = "Upholstered Furniture Living Room and Sofas";
    dashboard_comprador_label_outdoor = "Outdoor and Garden Furniture";
    dashboard_comprador_label_textil = "Textile, Leader and Vinyl";
    dashboard_comprador_label_others = "Others";

    dashboard_comprador_label_abrasives = "Abrasives";
    dashboard_comprador_label_furniture_fittings = "Furtinure Fittings";
    dashboard_comprador_label_tools_equipment = "Tools and equipment";
    dashboard_comprador_label_wood_boards = "Woods and boards";
    dashboard_comprador_label_machinery = "Machinery";
    dashboard_comprador_label_glues_adhesives = "Glues and adhesives";
    dashboard_comprador_label_paints_coatings = "Paints and Coatings";
    dashboard_comprador_label_polymers = "Polymers";
    dashboard_comprador_label_software = "Software";    

    dashboard_comprador_label_event = "*¿How did you find out about the event?";
    dashboard_comprador_label_findoutemail= "Email";
    dashboard_comprador_label_findoutmail= "Traditional Mail";
    dashboard_comprador_label_findoutradio= "Radio";
    dashboard_comprador_label_findoutnewspaper= "Newspaper";
    dashboard_comprador_label_findoutmagazine= "Magazine (Wich One?)";
    dashboard_comprador_label_findoutbillboard= "BillBoard";
    dashboard_comprador_label_findoutwebsite= "Web Site";
    dashboard_comprador_label_findoutotherwebsite= "Others Web Sites";
    dashboard_comprador_label_findoutsocialnetworks= "Social Networks";
    dashboard_comprador_label_findoutrecomendation= "Recommendation";
    dashboard_comprador_label_findoutother= "Other";
    dashboard_comprador_label_mainactivity = "*Primary Activity";
    dashboard_comprador_label_mainactivity_directSales = "Retail";
    dashboard_comprador_label_mainactivity_wholesale = "Wholesale";
    dashboard_comprador_label_mainactivity_importer = "Importer";
    dashboard_comprador_label_mainactivity_exporter = "Exporter";
    dashboard_comprador_label_mainactivity_construction = "Construction";
    dashboard_comprador_label_mainactivity_services = "Interior Design or Architectural Services";
    dashboard_comprador_label_interest = "Target";
    dashboard_comprador_label_interest_title = "*Products you are interested in";
    dashboard_comprador_label_interest_corporate = "Business and Commercial";
    dashboard_comprador_label_interest_hotels ="Hotels, Restaurant and CoffeShop";
    dashboard_comprador_label_interest_residential ="Home Furnishing";
    dashboard_comprador_label_interest_construction ="Construction";
    dashboard_comprador_label_interest_otros ="Others";
    dashboard_comprador_button_registro ="Register";
    dashboard_comprador_button_update ="Update";
    dashboard_comprador_label_advice =  "*By submitting your registration you are accepting our Terms and Conditions as set forth in our ";
    dashboard_comprador_label_privacy = "Privacy Notice."
    dashboard_comprador_label_privacy_url = "https://www.expomuebleinternacional.com.mx/es/privacity";
}

export default LocalizationEn;
