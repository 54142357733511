import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateAttendee, getAttendeeByAttendeeId } from "../../actions/attendeeActions"
import classnames from "classnames";
import materialize from "materialize-css";
import Localization from "../localization/localization"

class CompradorEdit extends Component {
  
    constructor() {
    super();

    this.state = {
        ////// Personal
        nacimiento: "",
        sexo: "",
        nombres:"",
        apellidos:"",
        email:"",
        celular:"",
        telefono:"",
        domicilio:"",
        colonia:"",
        ciudad:"",
        codigoPostal:"",
        estado:"",
        pais:"MX",
        //// Empresa
        nombreEmpresa:"",
        razonSocial:"",
        cargo:"",
        rfc:"",
        emailEmpresa:"",
        wwwEmpresa:"",
        //// Giro de la empresa
        giroFabricanteMuebles: false,
        giroComerciante: false,
        giroDiseniador: false,
        giroArquitectoConsultor: false,
        giroMaderero: false,
        giroTapiceroCarpintero: false,
        giroOtros: "",
        ///// Interes
        interesesAbrasivos: false,
        interesesAccesorios: false,
        interesesHerrajes: false,
        interesesHerramientasyEquipos: false, 
        interesesMaderasyChapas: false,
        interesesMaquinaria: false,
        interesesPegamentosyAdhesivos: false,
        interesesPinturasyRecubrimientos: false,
        interesesPolimeros: false,
        interesesServicios: false,
        interesesSoftware: false,
        interesesTapizados: false,
        interesesOtrosTexto: "",
        //// Enteraste
        enterasteCorreoElectronico: false,
        enterasteCorreoPostal: false,
        enterasteEspectaculares: false,
        enterasteInternet: false,
        enterasteOtrosTexto: "",
        enterasteOtroWww: false,
        enterastePeriodico: false,
        enterasteRadio: false,
        enterasteRecomendacion: false,
        enterasteRedesSociales: false,
        enterasteRevistaTexto: "",
        enteroEvento: "",
        /////
        principalActividad: "",
        productosInteres: "",
        categoria: "COMPRADOR",
        tipoRegistro: "pre",
        userId: "",

        locale:"es",
        errors: {}
    };

    this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
   /*
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }*/
    materialize.AutoInit();

    //getAttendeeByUserId(this.props.match.params.id)
    getAttendeeByAttendeeId(this.props.match.params.id)
    .then(response => {
          this.setState({
            nacimiento: response.data.nacimiento,
            sexo: response.data.sexo,
            nombres: response.data.nombres,
            apellidos: response.data.apellidos,
            email: response.data.email,
            celular: response.data.celular,
            telefono: response.data.telefono,
            domicilio: response.data.domicilio,
            colonia: response.data.colonia,
            ciudad: response.data.ciudad,
            codigoPostal: response.data.codigoPostal,
            estado: response.data.estado,
            pais: response.data.pais,
            nombreEmpresa: response.data.nombreEmpresa,
            razonSocial: response.data.razonSocial,
            cargo: response.data.cargo,
            rfc: response.data.rfc,
            emailEmpresa: response.data.emailEmpresa,
            wwwEmpresa: response.data.wwwEmpresa,

            giroFabricanteMuebles: ((response.data.giroFabricanteMuebles === "true") ? true : false),
            giroComerciante: ((response.data.giroComerciante === "true") ? true : false),
            giroDiseniador: ((response.data.giroDiseniador=== "true") ? true : false),
            giroArquitectoConsultor: ((response.data.giroArquitectoConsultor === "true") ? true : false),
            giroMaderero: ((response.data.giroMaderero === "true") ? true : false),
            giroTapiceroCarpintero: ((response.data.giroTapiceroCarpintero === "true") ? true : false),
            giroOtros: response.data.giroOtros,

            interesesAbrasivos: ((response.data.interesesAbrasivos === "true") ? true : false),
            interesesAccesorios: ((response.data.interesesAccesorios === "true") ? true : false),
            interesesHerrajes: ((response.data.interesesHerrajes === "true") ? true : false),
            interesesHerramientasyEquipos:  ((response.data.interesesHerramientasyEquipos === "true") ? true : false),
            interesesMaderasyChapas: ((response.data.interesesMaderasyChapas === "true") ? true : false),
            interesesMaquinaria: ((response.data.interesesMaquinaria === "true") ? true : false), 
            interesesPegamentosyAdhesivos: ((response.data.interesesPegamentosyAdhesivos === "true") ? true : false),
            interesesPinturasyRecubrimientos: ((response.data.interesesPinturasyRecubrimientos === "true") ? true : false),
            interesesPolimeros: ((response.data.interesesPolimeros === "true") ? true : false),
            interesesServicios: ((response.data.interesesServicios === "true") ? true : false),
            interesesSoftware:  ((response.data.interesesSoftware === "true") ? true : false),
            interesesTapizados: ((response.data.interesesTapizados === "true") ? true : false),
            interesesOtrosTexto: response.data.interesesOtrosTexto,

            enterasteCorreoElectronico: ((response.data.enterasteCorreoElectronico === "true") ? true : false),
            enterasteCorreoPostal: ((response.data.enterasteCorreoPostal === "true") ? true : false),
            enterasteEspectaculares: ((response.data.enterasteEspectaculares === "true") ? true : false),
            enterasteInternet: ((response.data.enterasteInternet === "true") ? true : false),
            enterasteOtrosTexto: response.data.enterasteOtrosTexto,
            enterasteOtroWww: ((response.data.enterasteOtroWww === "true") ? true : false),
            enterastePeriodico: ((response.data.enterastePeriodico === "true") ? true : false),
            enterasteRadio: ((response.data.enterasteRadio === "true") ? true : false),
            enterasteRecomendacion: ((response.data.enterasteRecomendacion === "true") ? true : false),
            enterasteRedesSociales: ((response.data.enterasteRedesSociales === "true") ? true : false),
            enterasteRevistaTexto: response.data.enterasteRevistaTexto,
            enteroEvento: response.data.enteroEvento,
            productosInteres: response.data.productosInteres,
            locale: response.data.locale,
            userId: response.data.user
          });
          materialize.AutoInit();
          materialize.updateTextFields();
    })
    .catch(error=> console.log(error));
  
   materialize.updateTextFields();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id;

    this.setState({
      [id]: value
    });
  };

  onSubmit = e => {
    e.preventDefault();

    let language = localStorage.getItem("exmi_language");
    if (language == null)
      language = "es";

    //const { user } = this.props.auth;

    const existingAttendee = {
      userId: this.state.userId,
      nacimiento: this.state.nacimiento,
      sexo: this.state.sexo,
      nombres: this.state.nombres,
      apellidos: this.state.apellidos,
      email: this.state.email,
      celular: this.state.celular,
      telefono: this.state.telefono,
      domicilio: this.state.domicilio,
      colonia: this.state.colonia,
      ciudad: this.state.ciudad,
      codigoPostal: this.state.codigoPostal,
      estado: this.state.estado,
      pais: this.state.pais,
      //// Empresa
      nombreEmpresa:this.state.nombreEmpresa,
      razonSocial: this.state.razonSocial,
      cargo: this.state.cargo,
      rfc: this.state.rfc,
      emailEmpresa: this.state.emailEmpresa,
      wwwEmpresa: this.state.wwwEmpresa,
      
      //// Giro de la empresa
      giroFabricanteMuebles: this.state.giroFabricanteMuebles,
      giroComerciante: this.state.giroComerciante,
      giroDiseniador: this.state.giroDiseniador,
      giroArquitectoConsultor: this.state.giroArquitectoConsultor,
      giroMaderero: this.state.giroMaderero,
      giroTapiceroCarpintero: this.state.giroTapiceroCarpintero,
      giroOtros: this.state.giroOtros,
      ///// Interes
      interesesAbrasivos: this.state.interesesAbrasivos,
      interesesAccesorios: this.state.interesesAccesorios,
      interesesHerrajes: this.state.interesesHerrajes,
      interesesHerramientasyEquipos: this.state.interesesHerramientasyEquipos,
      interesesMaderasyChapas: this.state.interesesMaderasyChapas,
      interesesMaquinaria: this.state.interesesMaquinaria,
      interesesPegamentosyAdhesivos: this.state.interesesPegamentosyAdhesivos,
      interesesPinturasyRecubrimientos: this.state.interesesPinturasyRecubrimientos,
      interesesPolimeros: this.state.interesesPolimeros,
      interesesServicios: this.state.interesesServicios,
      interesesSoftware: this.state.interesesSoftware,
      interesesTapizados: this.state.interesesTapizados,
      interesesOtrosTexto: this.state.interesesOtrosTexto,

      //// Enteraste
      enterasteCorreoElectronico: this.state.enterasteCorreoElectronico,
      enterasteCorreoPostal: this.state.enterasteCorreoPostal,
      enterasteEspectaculares: this.state.enterasteEspectaculares,
      enterasteInternet: this.state.enterasteInternet,
      enterasteOtrosTexto: this.state.enterasteOtrosTexto,
      enterasteOtroWww: this.state.enterasteOtroWww,
      enterastePeriodico: this.state.enterastePeriodico,
      enterasteRadio: this.state.enterasteRadio,
      enterasteRecomendacion: this.state.enterasteRecomendacion,
      enterasteRedesSociales: this.state.enterasteRedesSociales,
      enterasteRevistaTexto: this.state.enterasteRevistaTexto,
      enteroEvento: this.state.enteroEvento,
      /////
      productosInteres: this.state.productosInteres,
      categoria: this.state.categoria,
      tipoRegistro: this.state.tipoRegistro,

      locale: language
    };
    

    this.props.updateAttendee(this.props.match.params.id, existingAttendee, this.props.history);
  };

  render() {
    const { errors } = this.state;

    return (
      <div className="container ">
        <div className="login row">
          <div className="col s12 m8 l8">
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <h4>
                <b>{this.locale.dashboard_comprador_label_personalinformation}</b>
              </h4>
            </div>
            
            <form noValidate onSubmit={this.onSubmit}>           
              <div className="input-field col s12">
                <input
                autoFocus
                  onChange={this.onChange}
                  value={this.state.nombres}
                  error={errors.nombres}
                  id="nombres"
                  type="text"
                  className={classnames("", {
                    invalid: errors.nombres
                  })}
                />
                <label htmlFor="nombres">{this.locale.dashboard_comprador_label_name}</label>
                <span className="red-text">{errors.nombres}</span>
              </div>           
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.apellidos}
                  error={errors.apellidos}
                  id="apellidos"
                  type="text"
                  className={classnames("", {
                    invalid: errors.apellidos
                  })}
                />
                <label htmlFor="apellidos">{this.locale.dashboard_comprador_label_lastname}</label>
                <span className="red-text">{errors.apellidos}</span>
              </div>
              <div className="input-field col s12">
                <input
                  disabled
                  placeholder=""
                  onChange={this.onChange}
                  value={this.state.email}
                  error={errors.email}
                  id="email"
                  type="email"
                  className={classnames("", {
                    invalid: errors.email
                  })}
                />
                <label htmlFor="email"  className="active">{this.locale.dashboard_comprador_label_mail}</label>
                <span className="red-text">{errors.email}</span>
              </div> 
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.celular}
                  error={errors.celular}
                  id="celular"
                  type="text"
                  className={classnames("", {
                    invalid: errors.celular
                  })}
                />
                <label htmlFor="celular">{this.locale.dashboard_comprador_label_cellphone}</label>
                <span className="red-text">{errors.celular}</span>
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.telefono}
                  error={errors.telefono}
                  id="telefono"
                  type="text"
                  className={classnames("", {
                    invalid: errors.telefono
                  })}
                />
                <label htmlFor="telefono">{this.locale.dashboard_comprador_label_phone}</label>
                <span className="red-text">{errors.telefono}</span>
              </div>     
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.domicilio}
                  error={errors.domicilio}
                  id="domicilio"
                  type="text"
                  className={classnames("", {
                    invalid: errors.domicilio
                  })}
                />
                <label htmlFor="domicilio">{this.locale.dashboard_comprador_label_address}</label>
                <span className="red-text">{errors.domicilio}</span>
              </div>    
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.colonia}
                  error={errors.colonia}
                  id="colonia"
                  type="text"
                  className={classnames("", {
                    invalid: errors.colonia
                  })}
                />
                <label htmlFor="colonia">{this.locale.dashboard_comprador_label_neighbordhood}</label>
                <span className="red-text">{errors.colonia}</span>
                </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.ciudad}
                  error={errors.ciudad}
                  id="ciudad"
                  type="text"
                  className={classnames("", {
                    invalid: errors.ciudad
                  })}
                />
                <label htmlFor="colonia">{this.locale.dashboard_comprador_label_city}</label>
                <span className="red-text">{errors.ciudad}</span>                
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.codigoPostal}
                  error={errors.codigoPostal}
                  id="codigoPostal"
                  type="text"
                  className={classnames("", {
                    invalid: errors.codigoPostal
                  })}
                />
                <label htmlFor="colonia">{this.locale.dashboard_comprador_label_zipcode}</label>
                <span className="red-text">{errors.codigoPostal}</span>                
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.estado}
                  error={errors.estado}
                  id="estado"
                  type="text"
                  className={classnames("", {
                    invalid: errors.estado
                  })}
                />
                <label htmlFor="estado">{this.locale.dashboard_comprador_label_state}</label>
                <span className="red-text">{errors.estado}</span>                
              </div>    
              <div className="input-field col s12">
              <select value={this.state.pais} onChange={this.onChange} id="pais">
                <option value="" disabled>
                    {this.locale.dashboard_comprador_label_country}
                </option>
                  <option value="MX">México</option>
                  <option value="US">Estados Unidos</option>
                  <option value="AF">Afganistán</option>
                  <option value="AL">Albania</option>
                  <option value="DE">Alemania</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AI">Anguilla</option>
                  <option value="AQ">Antártida</option>
                  <option value="AG">Antigua y Barbuda</option>
                  <option value="AN">Antillas Holandesas</option>
                  <option value="SA">Arabia Saudí</option>
                  <option value="DZ">Argelia</option>
                  <option value="AR">Argentina</option>
                  <option value="AM">Armenia</option>
                  <option value="AW">Aruba</option>
                  <option value="AU">Australia</option>
                  <option value="AT">Austria</option>
                  <option value="AZ">Azerbaiyán</option>
                  <option value="BS">Bahamas</option>
                  <option value="BH">Bahrein</option>
                  <option value="BD">Bangladesh</option>
                  <option value="BB">Barbados</option>
                  <option value="BE">Bélgica</option>
                  <option value="BZ">Belice</option>
                  <option value="BJ">Benin</option>
                  <option value="BM">Bermudas</option>
                  <option value="BY">Bielorrusia</option>
                  <option value="MM">Birmania</option>
                  <option value="BO">Bolivia</option>
                  <option value="BA">Bosnia y Herzegovina</option>
                  <option value="BW">Botswana</option>
                  <option value="BR">Brasil</option>
                  <option value="BN">Brunei</option>
                  <option value="BG">Bulgaria</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="BT">Bután</option>
                  <option value="CV">Cabo Verde</option>
                  <option value="KH">Camboya</option>
                  <option value="CM">Camerún</option>
                  <option value="CA">Canadá</option>
                  <option value="TD">Chad</option>
                  <option value="CL">Chile</option>
                  <option value="CN">China</option>
                  <option value="CY">Chipre</option>
                  <option value="VA">Ciudad del Vaticano (Santa Sede)</option>
                  <option value="CO">Colombia</option>
                  <option value="KM">Comores</option>
                  <option value="CG">Congo</option>
                  <option value="CD">Congo, República Democrática del</option>
                  <option value="KR">Corea</option>
                  <option value="KP">Corea del Norte</option>
                  <option value="CI">Costa de Marfíl</option>
                  <option value="CR">Costa Rica</option>
                  <option value="HR">Croacia (Hrvatska)</option>
                  <option value="CU">Cuba</option>
                  <option value="DK">Dinamarca</option>
                  <option value="DJ">Djibouti</option>
                  <option value="DM">Dominica</option>
                  <option value="EC">Ecuador</option>
                  <option value="EG">Egipto</option>
                  <option value="SV">El Salvador</option>
                  <option value="AE">Emiratos Árabes Unidos</option>
                  <option value="ER">Eritrea</option>
                  <option value="SI">Eslovenia</option>
                  <option value="ES">España</option>                  
                  <option value="EE">Estonia</option>
                  <option value="ET">Etiopía</option>
                  <option value="FJ">Fiji</option>
                  <option value="PH">Filipinas</option>
                  <option value="FI">Finlandia</option>
                  <option value="FR">Francia</option>
                  <option value="GA">Gabón</option>
                  <option value="GM">Gambia</option>
                  <option value="GE">Georgia</option>
                  <option value="GH">Ghana</option>
                  <option value="GI">Gibraltar</option>
                  <option value="GD">Granada</option>
                  <option value="GR">Grecia</option>
                  <option value="GL">Groenlandia</option>
                  <option value="GP">Guadalupe</option>
                  <option value="GU">Guam</option>
                  <option value="GT">Guatemala</option>
                  <option value="GY">Guayana</option>
                  <option value="GF">Guayana Francesa</option>
                  <option value="GN">Guinea</option>
                  <option value="GQ">Guinea Ecuatorial</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="HT">Haití</option>
                  <option value="HN">Honduras</option>
                  <option value="HU">Hungría</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IQ">Irak</option>
                  <option value="IR">Irán</option>
                  <option value="IE">Irlanda</option>
                  <option value="BV">Isla Bouvet</option>
                  <option value="CX">Isla de Christmas</option>
                  <option value="IS">Islandia</option>
                  <option value="KY">Islas Caimán</option>
                  <option value="CK">Islas Cook</option>
                  <option value="CC">Islas de Cocos o Keeling</option>
                  <option value="FO">Islas Faroe</option>
                  <option value="HM">Islas Heard y McDonald</option>
                  <option value="FK">Islas Malvinas</option>
                  <option value="MP">Islas Marianas del Norte</option>
                  <option value="MH">Islas Marshall</option>
                  <option value="UM">Islas menores de Estados Unidos</option>
                  <option value="PW">Islas Palau</option>
                  <option value="SB">Islas Salomón</option>
                  <option value="SJ">Islas Svalbard y Jan Mayen</option>
                  <option value="TK">Islas Tokelau</option>
                  <option value="TC">Islas Turks y Caicos</option>
                  <option value="VI">Islas Vírgenes (EEUU)</option>
                  <option value="VG">Islas Vírgenes (Reino Unido)</option>
                  <option value="WF">Islas Wallis y Futuna</option>
                  <option value="IL">Israel</option>
                  <option value="IT">Italia</option>
                  <option value="JM">Jamaica</option>
                  <option value="JP">Japón</option>
                  <option value="JO">Jordania</option>
                  <option value="KZ">Kazajistán</option>
                  <option value="KE">Kenia</option>
                  <option value="KG">Kirguizistán</option>
                  <option value="KI">Kiribati</option>
                  <option value="KW">Kuwait</option>
                  <option value="LA">Laos</option>
                  <option value="LS">Lesotho</option>
                  <option value="LV">Letonia</option>
                  <option value="LB">Líbano</option>
                  <option value="LR">Liberia</option>
                  <option value="LY">Libia</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lituania</option>
                  <option value="LU">Luxemburgo</option>
                  <option value="MK">Macedonia, Ex-República Yugoslava de</option>
                  <option value="MG">Madagascar</option>
                  <option value="MY">Malasia</option>
                  <option value="MW">Malawi</option>
                  <option value="MV">Maldivas</option>
                  <option value="ML">Malí</option>
                  <option value="MT">Malta</option>
                  <option value="MA">Marruecos</option>
                  <option value="MQ">Martinica</option>
                  <option value="MU">Mauricio</option>
                  <option value="MR">Mauritania</option>
                  <option value="YT">Mayotte</option>
                  <option value="FM">Micronesia</option>
                  <option value="MD">Moldavia</option>
                  <option value="MC">Mónaco</option>
                  <option value="MN">Mongolia</option>
                  <option value="MS">Montserrat</option>
                  <option value="MZ">Mozambique</option>
                  <option value="NA">Namibia</option>
                  <option value="NR">Nauru</option>
                  <option value="NP">Nepal</option>
                  <option value="NI">Nicaragua</option>
                  <option value="NE">Níger</option>
                  <option value="NG">Nigeria</option>
                  <option value="NU">Niue</option>
                  <option value="NF">Norfolk</option>
                  <option value="NO">Noruega</option>
                  <option value="NC">Nueva Caledonia</option>
                  <option value="NZ">Nueva Zelanda</option>
                  <option value="OM">Omán</option>
                  <option value="NL">Países Bajos</option>
                  <option value="PA">Panamá</option>
                  <option value="PG">Papúa Nueva Guinea</option>
                  <option value="PK">Paquistán</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Perú</option>
                  <option value="PN">Pitcairn</option>
                  <option value="PF">Polinesia Francesa</option>
                  <option value="PL">Polonia</option>
                  <option value="PT">Portugal</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="QA">Qatar</option>
                  <option value="UK">Reino Unido</option>
                  <option value="CF">República Centroafricana</option>
                  <option value="CZ">República Checa</option>
                  <option value="ZA">República de Sudáfrica</option>
                  <option value="DO">República Dominicana</option>
                  <option value="SK">República Eslovaca</option>
                  <option value="RE">Reunión</option>
                  <option value="RW">Ruanda</option>
                  <option value="RO">Rumania</option>
                  <option value="RU">Rusia</option>
                  <option value="EH">Sahara Occidental</option>
                  <option value="KN">Saint Kitts y Nevis</option>
                  <option value="WS">Samoa</option>
                  <option value="AS">Samoa Americana</option>
                  <option value="SM">San Marino</option>
                  <option value="VC">San Vicente y Granadinas</option>
                  <option value="SH">Santa Helena</option>
                  <option value="LC">Santa Lucía</option>
                  <option value="ST">Santo Tomé y Príncipe</option>
                  <option value="SN">Senegal</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leona</option>
                  <option value="SG">Singapur</option>
                  <option value="SY">Siria</option>
                  <option value="SO">Somalia</option>
                  <option value="LK">Sri Lanka</option>
                  <option value="PM">St Pierre y Miquelon</option>
                  <option value="SZ">Suazilandia</option>
                  <option value="SD">Sudán</option>
                  <option value="SE">Suecia</option>
                  <option value="CH">Suiza</option>
                  <option value="SR">Surinam</option>
                  <option value="TH">Tailandia</option>
                  <option value="TW">Taiwán</option>
                  <option value="TZ">Tanzania</option>
                  <option value="TJ">Tayikistán</option>
                  <option value="TF">Territorios franceses del Sur</option>
                  <option value="TP">Timor Oriental</option>
                  <option value="TG">Togo</option>
                  <option value="TO">Tonga</option>
                  <option value="TT">Trinidad y Tobago</option>
                  <option value="TN">Túnez</option>
                  <option value="TM">Turkmenistán</option>
                  <option value="TR">Turquía</option>
                  <option value="TV">Tuvalu</option>
                  <option value="UA">Ucrania</option>
                  <option value="UG">Uganda</option>
                  <option value="UY">Uruguay</option>
                  <option value="UZ">Uzbekistán</option>
                  <option value="VU">Vanuatu</option>
                  <option value="VE">Venezuela</option>
                  <option value="VN">Vietnam</option>
                  <option value="YE">Yemen</option>
                  <option value="YU">Yugoslavia</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabue</option>
              </select>
              <label>{this.locale.dashboard_comprador_label_country}</label>
              </div> 
              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <h4>
                <b>{this.locale.dashboard_comprador_label_companyinformation}</b>
              </h4>
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.nombreEmpresa}
                  error={errors.nombreEmpresa}
                  id="nombreEmpresa"
                  type="text"
                  className={classnames("", {
                    invalid: errors.nombreEmpresa
                  })}
                />
                <label htmlFor="nombreEmpresa">{this.locale.dashboard_comprador_label_nameofcompany}</label>
                <span className="red-text">{errors.nombreEmpresa}</span>                
            </div>                                                                              
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.razonSocial}
                  error={errors.razonSocial}
                  id="razonSocial"
                  type="text"
                  className={classnames("", {
                    invalid: errors.razonSocial
                  })}
                />
                <label htmlFor="razonSocial">{this.locale.dashboard_comprador_label_businessname}</label>
                <span className="red-text">{errors.razonSocial}</span>                
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.cargo}
                  error={errors.cargo}
                  id="cargo"
                  type="text"
                  className={classnames("", {
                    invalid: errors.cargo
                  })}
                />
                <label htmlFor="cargo">{this.locale.dashboard_comprador_label_companyposition}</label>
                <span className="red-text">{errors.cargo}</span>                
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.rfc}
                  error={errors.rfc}
                  id="rfc"
                  type="text"
                  className={classnames("", {
                    invalid: errors.rfc
                  })}
                />
                <label htmlFor="rfc">{this.locale.dashboard_comprador_label_rfc}</label>
                <span className="red-text">{errors.rfc}</span>                
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.emailEmpresa}
                  error={errors.emailEmpresa}
                  id="emailEmpresa"
                  type="email"
                  className={classnames("", {
                    invalid: errors.emailEmpresa
                  })}
                />
                <label htmlFor="correoElectronico">{this.locale.dashboard_comprador_label_companyemail}</label>
                <span className="red-text">{errors.emailEmpresa}</span>
              </div> 
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.wwwEmpresa}
                  error={errors.wwwEmpresa}
                  id="wwwEmpresa"
                  type="text"
                  className={classnames("", {
                    invalid: errors.wwwEmpresa
                  })}
                />
                <label htmlFor="wwwEmpresa">{this.locale.dashboard_comprador_label_companywebpage}</label>
                <span className="red-text">{errors.wwwEmpresa}</span>                
              </div>
              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <h4>
                <b>{this.locale.dashboard_comprador_label_business}</b>
              </h4>
              </div>  
              <div className="input-field col s8">{this.locale.dashboard_comprador_label_furniture_manufacturer}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="giroFabricanteMuebles" onChange={this.onChange} checked={this.state.giroFabricanteMuebles}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>
            
              <div className="input-field col s8">{this.locale.dashboard_comprador_label_seller}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="giroComerciante" onChange={this.onChange} checked={this.state.giroComerciante}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>            

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_designer}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="giroDiseniador" onChange={this.onChange} checked={this.state.giroDiseniador}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>            
              
              <div className="input-field col s8">{this.locale.dashboard_comprador_label_architect_consultant}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="giroArquitectoConsultor" onChange={this.onChange} checked={this.state.giroArquitectoConsultor}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>   

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_wood_seller}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="giroMaderero" onChange={this.onChange} checked={this.state.giroMaderero}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>           

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_upholster_carpenter}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="giroTapiceroCarpintero" onChange={this.onChange} checked={this.state.giroTapiceroCarpintero}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.giroOtros}
                  error={errors.giroOtros}
                  id="giroOtros"
                  type="text"
                  className={classnames("", {
                    invalid: errors.giroOtros
                  })}
                />
                <label htmlFor="giroOtros">{this.locale.dashboard_comprador_label_others}</label>
                <span className="red-text">{errors.giroOtros}</span>                
              </div>                          
              <div className="input-field col s12"><span className="red-text">{errors.giro}</span></div>                                                  
              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <h4>
                <b>{this.locale.dashboard_comprador_label_interest_title}</b>
              </h4>
              </div>

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_abrasives}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="interesesAbrasivos" onChange={this.onChange} checked={this.state.interesesAbrasivos}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_accesories}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="interesesAccesorios" onChange={this.onChange} checked={this.state.interesesAccesorios}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>



              <div className="input-field col s8">{this.locale.dashboard_comprador_label_furniture_fittings}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="interesesHerrajes" onChange={this.onChange} checked={this.state.interesesHerrajes}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_tools_equipment}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="interesesHerramientasyEquipos" onChange={this.onChange} checked={this.state.interesesHerramientasyEquipos}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>
              <div className="input-field col s8">{this.locale.dashboard_comprador_label_wood_boards}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="interesesMaderasyChapas" onChange={this.onChange} checked={this.state.interesesMaderasyChapas}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_machinery}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="interesesMaquinaria" onChange={this.onChange} checked={this.state.interesesMaquinaria}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>

              
              <div className="input-field col s8">{this.locale.dashboard_comprador_label_glues_adhesives}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="interesesPegamentosyAdhesivos" onChange={this.onChange} checked={this.state.interesesPegamentosyAdhesivos}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>
              
              <div className="input-field col s8">{this.locale.dashboard_comprador_label_paints_coatings}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="interesesPinturasyRecubrimientos" onChange={this.onChange} checked={this.state.interesesPinturasyRecubrimientos}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_polymers}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="interesesPolimeros" onChange={this.onChange} checked={this.state.interesesPolimeros}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_services}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="interesesServicios" onChange={this.onChange} checked={this.state.interesesServicios}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_software }</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="interesesSoftware" onChange={this.onChange} checked={this.state.interesesSoftware}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_upholstery}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="interesesTapizados" onChange={this.onChange} checked={this.state.interesesTapizados}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>

              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.interesesOtrosTexto}
                  error={errors.interesesOtrosTexto}
                  id="interesesOtrosTexto"
                  type="text"
                  className={classnames("", {
                    invalid: errors.interesesOtrosTexto
                  })}
                />
                <label htmlFor="interesesOtrosTexto">{this.locale.dashboard_comprador_label_others}</label>
                <span className="red-text">{errors.interesesOtrosTexto}</span>                
              </div>
              <div className="input-field col s12"><span className="red-text">{errors.intereses}</span></div>                     
              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <h4>
                <b>{this.locale.dashboard_comprador_label_event}</b>
              </h4>
              </div>  
              <div className="input-field col s8">{this.locale.dashboard_comprador_label_findoutemail}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="enterasteCorreoElectronico" onChange={this.onChange} checked={this.state.enterasteCorreoElectronico}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_findoutmail}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="enterasteCorreoPostal" onChange={this.onChange} checked={this.state.enterasteCorreoPostal}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>      

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_findoutradio}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="enterasteRadio" onChange={this.onChange} checked={this.state.enterasteRadio}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>      

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_findoutnewspaper}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="enterastePeriodico" onChange={this.onChange} checked={this.state.enterastePeriodico}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>      
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.enterasteRevistaTexto}
                  error={errors.enterasteRevistaTexto}
                  id="enterasteRevistaTexto"
                  type="text"
                  className={classnames("", {
                    invalid: errors.enterasteRevistaTexto
                  })}
                />
                <label htmlFor="enterasteRevistaTexto">{this.locale.dashboard_comprador_label_findoutmagazine}</label>
                <span className="red-text">{errors.enterasteRevistaTexto}</span>                
              </div>

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_findoutbillboard}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="enterasteEspectaculares" onChange={this.onChange} checked={this.state.enterasteEspectaculares}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>      

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_findoutwebsite}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="enterasteInternet" onChange={this.onChange} checked={this.state.enterasteInternet}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>      

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_findoutotherwebsite}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="enterasteOtroWww" onChange={this.onChange} checked={this.state.enterasteOtroWww}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>               

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_findoutsocialnetworks}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="enterasteRedesSociales" onChange={this.onChange} checked={this.state.enterasteRedesSociales}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>               

              <div className="input-field col s8">{this.locale.dashboard_comprador_label_findoutrecomendation}</div>
              <div className="input-field col s4">
              <div className="switch">
                <label>
                    <input type="checkbox" id="enterasteRecomendacion" onChange={this.onChange} checked={this.state.enterasteRecomendacion}/>
                    <span className="lever"></span>
              </label>
              </div>  
              </div>               

              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.enterasteOtrosTexto}
                  error={errors.enterasteOtrosTexto}
                  id="enterasteOtrosTexto"
                  type="text"
                  className={classnames("", {
                    invalid: errors.enterasteOtrosTexto
                  })}
                />
                <label htmlFor="enterasteRevistaTexto">{this.locale.dashboard_comprador_label_findoutother}</label>
                <span className="red-text">{errors.enterasteOtrosTexto}</span>                
              </div>      
              <div className="input-field col s12"><span className="red-text">{errors.enteraste}</span></div>                     

            <div className="input-field col s12">
              <select value={this.state.productosInteres} onChange={this.onChange} id="productosInteres">
                <option value="" disabled>
                   {this.locale.dashboard_comprador_label_interest}
                </option>
                <option value="Corporativo">{this.locale.dashboard_comprador_label_interest_corporate}</option>
                <option value="Hoteles, restaurantes y cafeterias">{this.locale.dashboard_comprador_label_interest_hotels}</option>
                <option value="Residencial">{this.locale.dashboard_comprador_label_interest_residential}</option>
                <option value="Construccion">{this.locale.dashboard_comprador_label_interest_construction}</option>
                <option value="Otros">{this.locale.dashboard_comprador_label_interest_otros}</option>
              </select>
              <label>{this.locale.dashboard_comprador_label_interest}</label>
            </div> 
            <div className="col s12"><span className="red-text">{errors.nombres}</span></div> 
            <div className="col s12"><span className="red-text">{errors.apellidos}</span></div>
            <div className="col s12"><span className="red-text">{errors.email}</span></div>
            <div className="col s12"><span className="red-text">{errors.telefono}</span></div>
            <div className="col s12"><span className="red-text">{errors.ciudad}</span></div>
            <div className="col s12"><span className="red-text">{errors.estado}</span></div>	
            <div className="col s12"><span className="red-text">{errors.nombreEmpresa}</span></div>  				
            <div className="col s12"><span className="red-text">{errors.razonSocial}</span></div>
            <div className="col s12"><span className="red-text">{errors.cargo}</span></div>
            <div className="col s12"><span className="red-text">{errors.rfc}</span></div>
            <div className="col s12"><span className="red-text">{errors.giro}</span></div>                  
            <div className="col s12"><span className="red-text">{errors.intereses}</span></div>                                                     				
            <div className="col s12"><span className="red-text">{errors.enterasteOtrosTexto}</span></div>            
            <div className="col s12">
              <label>{this.locale.dashboard_comprador_label_advice}</label>
              <a href={this.locale.dashboard_comprador_label_privacy_url}  target="_blank" rel="noopener noreferrer">{this.locale.dashboard_comprador_label_privacy}</a>
            </div>   
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <button
                  style={{
                    width: "150px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem"
                  }}
                  type="submit"
                  className="btn btn-large waves-effect waves-light hoverable orange darken-4"
                >
                  {this.locale.dashboard_comprador_button_registro}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}


CompradorEdit.propTypes = {
  updateAttendee : PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { updateAttendee }
)(withRouter(CompradorEdit));


