import axios from "axios";
import { GET_ERRORS } from "./types";

// Register Attendee
export const registerAttendee = (userData, history) => dispatch => {
  axios
    .post("/api/attendees/add", userData)
    .then(res => history.push("/compradorlanding?action=update&id="+res.data.user._id))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


// Actualiza Attendee
export const updateAttendee = (attendeeId, userData, history) => dispatch => {
  axios
    .post("/api/attendees/update/"+attendeeId, userData)
    .then(res => history.push("/compradorlanding?action=update&id="+userData.userId))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get Atendee Details
export const getAttendeeByUserId = (userId) => {
  try {
    return axios.get("/api/attendees/byUserId/"+ userId);
  } catch (error) {
    console.error(error)
  }
};

// Get Atendee Details by Email
export const getAttendeeByEmail = (email) => {
  try {
    return axios.get("/api/attendees/byEmail/"+ email);
  } catch (error) {
    console.error(error)
  }
};



// Get Atendee Details
export const getAttendeeByAttendeeId = (attendeeId) => {
  try {
    return axios.get("/api/attendees/"+ attendeeId);
  } catch (error) {
    console.error(error)
  }
};



// Get Atendee Details
export const ReSendInvitation = (userId) => {
  try {
    return axios.get("/api/attendees/resendinvitation/"+ userId);
  }
  catch (error) {
    console.error(error)
  }
};


// Register Student/invitated
export const registerGeneric = (userData, history) => dispatch => {
  axios
    .post("/api/attendees/add", userData)
    //.then(res=> console.log(res.data))
    .then(res => history.push("/landing?id="+res.data.toString()))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};
