
import React, { Component } from "react";
import queryString from 'query-string'
import { userDetails } from "../../actions/authActions";
import Localization from "../localization/localization";


class CompradorLanding extends Component {

    constructor() {
        super();
        this.state = {
          name: "",
          email:"",
          action:""
        };
        
        this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language")); 
      }

  componentDidMount() {
    const  params  = queryString.parse(this.props.location.search)

    this.setState({action:params.action});

    userDetails(params.id)
    .then(res => this.setState({name:res.data.name, email:res.data.email}))
    .catch(err => {
        console.log(err);
        return null;
    });
  }

  closeForm(e) {
    //window.opener = self;
    e.preventDefault();
    window.close();
  }

  render() {
    return (
      <div style={{ height: "75vh" }} className="container valign-wrapper">
        <div className="login row">
          <div className="col s12 m12 l12 center-align">
          { this.state.action === "add" ?     
            <>
            <h4>
              <p>{this.locale.dashboard_compradorlanding_label_hello} {this.state.name} {this.locale.dashboard_compradorlanding_label_message_success} 👏</p>
            </h4>
            <h6>
                {this.locale.dashboard_compradorlanding_label_message_sent} <b>{this.state.email}</b>
            </h6>
            <h6>
              {this.locale.dashboard_compradorlanding_label_spam}
            </h6>
            <p>
                {this.locale.dashboard_compradorlanding_label_message_note}
            </p>
            </>
            :
            <>
            <h4>
              <p>{this.locale.dashboard_compradorlanding_label_hello} {this.state.name} {this.locale.dashboard_compradorlanding_label_update_success} 👏</p>
            </h4>
            </>
        }   
              <button
                  style={{
                    width: "200px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px"
                  }}
                  type="submit"
                  className="btn btn-large waves-effect waves-light hoverable orange darken-4"
                  onClick={(e) => {this.closeForm(e);}}
                >
                  {this.locale.dashboard_comprador_button_registro}
                </button>
              </div>
        </div>
      </div>
    );
  }
}

export default CompradorLanding;