class LocalizationEs
{
    ///// Home  Page /////
    layout_landing_label_welcome = "Bienvenido";
    layout_landing_label_eventname = "a Tecno Mueble Internacional";
    layout_landing_label_envenname_year = "2019 Registro";
    layout_landing_button_register =  "Registro";
    layout_landing_button_login =  "Iniciar sesión";
    
    ///// Login  Page /////
    layout_login_label_backtohome = "Inicio";
    layout_login_label_login =  "Iniciar sesión";
    layout_login_label_noaccount = "No tiene una cuenta?";
    layout_login_link_createaccount = "Cree una.";
    layout_login_link_forgotpassword = "Olvido su contraseña?";
    layout_login_label_email = "Correo Electronico";
    layout_login_label_password = "Contraseña";
    layout_login_button_login = "Iniciar sesión";

    /// Register Page ////
    auth_register_label_backtohome = "Inicio";
    auth_register_label_register = "Registro";
    auth_register_label_alreadyAccount = "Ya tiene cuenta? ";
    auth_register_label_login = "Iniciar sesión";
    auth_register_label_name = "Nombre";
    auth_register_label_email = "Correo electronico";
    auth_register_label_emailconfirmation = "Confirmacion Correo electronico";
    auth_register_label_password = "Contraseña";
    auth_register_label_passwordconfirmation = "Confirmacion Contraseña";
    auth_register_button_register = "Registro";

    /// Account Activation ////
    auth_activation_label_hello = "Hola ";    
    auth_activation_label_message_success = " tu cuenta fue activada satisfactoriamente ";
    auth_activation_label_message_failure = " tu cuenta no fue activada satisfactoriamente, intenta nuevamente mas tarde por favor";
    auth_activation_label_button_login = "Iniciar sesión";

    /// Email Activation  Message ////
    auth_emailactivation_label_thanks = "Gracias por registrarse a Tecno Mueble Internacional";
    auth_emailactivation_label_email_sent = "Un correo electronico fue enviado a tu cuenta, por favor revisa tu bandeja de entrada y sigue los pasos ahi descritos para completar tu registro.";
    auth_emailactivation_label_email_not_received = "No recibiste el correo de verification por parte de Tecno Mueble Internacional? Revisa el folder spam o haz click en el link siguiente";
    auth_emailactivation_button_resend = "Reenviar";
    auth_emailactivation_button_login = "Iniciar sesión";

    /// Student Register ////
    auth_student_label_formtitle = "Registro Estudiante";    
    auth_student_label_nombre = "*Nombre";
    auth_student_label_last_name ="*Apellidos";
    auth_student_label_email = "*Correo Electronico";
    auth_student_label_phone = "*Telefono";
    auth_student_label_university = "*Universidad";
    auth_student_label_carrer = "*Carrera";
    auth_student_button_register = "Registro";

    /// Landing Registry Success ////
    auth_landingsuccess_label_app_name = "EXMI";
    auth_landingsuccess_label_hello = "Hola ";
    auth_landingsuccess_label_message_success = " tu registro fue completado satisfactoriamente ";
    auth_landingsuccess_label_message_sent = "Un correo electronico de confirmacion fue enviado a ";
    auth_landingsuccess_label_message_note = "Para ingresar al evento, es necesario presentar ese correo en registro (impreso o digital) junto a una identificacion oficial para obtener tu gafete."

    /// Password Reset ////
    auth_passwordreset_label_backtohome = "Inicio";
    auth_passwordreset_label_formtitle = "Reestablecer contraseña";
    auth_passwordreset_label_usuario = "Usuario";
    auth_passwordreset_label_email = "Correo Electronico";
    auth_passwordreset_label_password = "Contraseña";    
    auth_passwordreset_label_passwordconfirmation = "Confirmar Contraseña";  
    auth_passwordreset_button_confirmation = "Aceptar";  

    /// Password Reset Email Sent ////
    auth_passwordresetemail_label_backtohome = "Inicio";
    auth_passwordresetemail_label_formtitle = "Reestablecer contraseña";
    auth_passwordresetemail_label_message = "Para restabler tu contraseña, introduce tu dirección de correo electrónico y presiona el botón a continuación. Sigue las instrucciones incluidas en el correo electrónico que recibirás.";
    auth_passwordresetemail_label_email = "Correo Electronico";    
    auth_passwordreset_button_confirmation = "Enviar correo"; 
    
    // Visitante
    auth_visitante_label_formtitle = "Registro Invitado";
    auth_visitante_label_name = "*Nombre";
    auth_visitante_label_lastname ="*Apellidos";
    auth_visitante_label_email ="*Correo Electronico";
    auth_visitante_label_companyname = "*Nombre de la empresa";
    auth_visitante_button_register = "Registro";


    /// Comprador Landing Success ///
    dashboard_compradorlanding_label_hello = "Hola ";
    dashboard_compradorlanding_label_message_success = " tu registro fue completado satisfactoriamente ";
    dashboard_compradorlanding_label_message_sent = "Un correo electronico de confirmacion fue enviado a ";
    dashboard_compradorlanding_label_spam = " No olvides revisar tu carpeta de Spam o Bandeja de No Deseados.";
    dashboard_compradorlanding_label_message_note = "Para ingresar al evento, es necesario presentar ese correo en registro (impreso o digital) junto a una identificacion oficial para obtener tu gafete."
    dashboard_compradorlanding_label_update_success = " tus datos fueron actualizados sastisfactoriamente "; 
    dashboard_compradorlanding_button_home = "Inicio";
    dashboard_compradorlanding_toast_success = "Correo Electronico enviado";
    dashboard_compradorlanding_toast_failure = "Correo Electronico no enviado, intente mas tarde por favor";


    /// Home ////
    dashboard_dashboard_label_hello = "Hola ";
    dashboard_dashboard_label_message_success = "Iniciaste sension satisfactoriamente en";
    dashboard_dashboard_label_message_registryconfirmation = "Ya estás registrado en Tecno Mueble";
    dashboard_dashboard_button_updatedataregister = "Modificar Registro";
    dashboard_dashboard_button_resendinvitation = "Reenviar Invitacion";
    dashboard_dashboard_button_logout = "Cerrar sesión";
    dashboard_dashboard_button_register = "Registrarse";

    ///// Comprador  Page /////
    dashboard_comprador_label_backtohome =  "Incio";
    dashboard_comprador_label_personalinformation = "Informacion Personal";
    dashboard_comprador_label_yearofbirth = "Año de Nacimiento";
    dashboard_comprador_label_sex = "Sexo";
    dashboard_comprador_label_female = "Femenino";
    dashboard_comprador_label_male = "Masculino";
    dashboard_comprador_label_name = "*Nombre";
    dashboard_comprador_label_lastname = "*Apellidos";
    dashboard_comprador_label_mail = "*Correo Electonico";
    dashboard_comprador_label_cellphone = "Celular";
    dashboard_comprador_label_phone = "*Telefono";
    dashboard_comprador_label_address = "Direccion";
    dashboard_comprador_label_neighbordhood = "Colonia";
    dashboard_comprador_label_city = "*Ciudad";
    dashboard_comprador_label_zipcode = "Codigo Postal";
    dashboard_comprador_label_state = "*Estado";
    dashboard_comprador_label_country = "*Pais";
    dashboard_comprador_label_companyinformation = "*Informacion de la Empresa";
    dashboard_comprador_label_nameofcompany = "*Nombre de la Empresa";
    dashboard_comprador_label_businessname = "*Nombre del Negocio";
    dashboard_comprador_label_companyposition = "*Posicion en la Empresa";
    dashboard_comprador_label_rfc = "*RFC";
    dashboard_comprador_label_companyemail = "Correo Electronico Empresa";
    dashboard_comprador_label_companywebpage = "Pagina Web Empresa";

    dashboard_comprador_label_business = "*Giro de Negocio";
    dashboard_comprador_label_furniture = "Muebleria";
    dashboard_comprador_label_deparmentalstore = "Tienda Departamental";
    dashboard_comprador_label_decorationstore = "Decoracion";
    dashboard_comprador_label_wholesale = "Mayorista";
    dashboard_comprador_label_construction = "Construccion"
    dashboard_comprador_label_restaurantbar = "Restaurant-Bar";
    dashboard_comprador_label_hotel = "Hotel";
    dashboard_comprador_label_decorationServices = "Servicios (Diseño de Interiores/Arquitectura)";
    
    dashboard_comprador_label_furniture_manufacturer = "Fabricante de Muebles";
    dashboard_comprador_label_seller = "Comerciante";
    dashboard_comprador_label_designer = "Diseñador";
    dashboard_comprador_label_architect_consultant = "Arquitecto/Consultor";
    dashboard_comprador_label_wood_seller = "Maderero";
    dashboard_comprador_label_upholster_carpenter = "Tapicero/Carpintero";

    dashboard_comprador_label_interest = "*Productos de Interes";
    dashboard_comprador_label_accesories = "Accesorios";
    dashboard_comprador_label_entertaimentcenters = "Centro de Entretenimiento y Libreros";
    dashboard_comprador_label_mattresses = "Colchones";
    dashboard_comprador_label_dinning = "Comedores";
    dashboard_comprador_label_children = "Infantiles y Juveniles";
    dashboard_comprador_label_office = "Muebles de oficina";
    dashboard_comprador_label_bedroom = "Recamaras y Literal";
    dashboard_comprador_label_services = "Servicios";
    dashboard_comprador_label_upholstery = "Tapizados";
    dashboard_comprador_label_outdoor = "Terraza y Jardin";
    dashboard_comprador_label_textil = "Textiles";
    dashboard_comprador_label_others = "Otros";

    dashboard_comprador_label_abrasives = "Abrasivos";
    dashboard_comprador_label_furniture_fittings = "Herrajes";
    dashboard_comprador_label_tools_equipment = "Herramientas y Equipos";
    dashboard_comprador_label_wood_boards = "Maderas y Chapas";
    dashboard_comprador_label_machinery = "Maquinaria";
    dashboard_comprador_label_glues_adhesives = "Pegamentos y Adhesivos";
    dashboard_comprador_label_paints_coatings = "Pinturas y Recubrimientos";
    dashboard_comprador_label_polymers = "Polimeros";
    dashboard_comprador_label_software = "Software";    

    dashboard_comprador_label_event = "*¿Cómo se enteró del evento?";
    dashboard_comprador_label_findoutemail= "Correo Electronico";
    dashboard_comprador_label_findoutmail= "Correo Postal";
    dashboard_comprador_label_findoutradio= "Radio";
    dashboard_comprador_label_findoutnewspaper= "Periodico";
    dashboard_comprador_label_findoutmagazine= "Revista (Cual?)";
    dashboard_comprador_label_findoutbillboard= "Espectacular";
    dashboard_comprador_label_findoutwebsite= "Sitio Web";
    dashboard_comprador_label_findoutotherwebsite= "Otros Sitios Web";
    dashboard_comprador_label_findoutsocialnetworks= "Redes Sociales";
    dashboard_comprador_label_findoutrecomendation= "Recomendacion";
    dashboard_comprador_label_findoutother= "Otro";
    dashboard_comprador_label_mainactivity = "*Principal Actividad";
    dashboard_comprador_label_mainactivity_directSales = "Venta Directa";
    dashboard_comprador_label_mainactivity_wholesale = "Mayorista";
    dashboard_comprador_label_mainactivity_importer = "Importador";
    dashboard_comprador_label_mainactivity_exporter = "Exportador";
    dashboard_comprador_label_mainactivity_construction = "Construccion";
    dashboard_comprador_label_mainactivity_services = "Servicios/Interiorismo/Arquitectura";
    dashboard_comprador_label_interest_title = "*Productos de Interes";
    dashboard_comprador_label_interest = "Mercado al que atiende";
    dashboard_comprador_label_interest_corporate = "Corporativo";
    dashboard_comprador_label_interest_hotels ="Hoteles, restaurantes y cafeterías";
    dashboard_comprador_label_interest_residential ="Residencial";
    dashboard_comprador_label_interest_construction ="Construcción";
    dashboard_comprador_label_interest_otros ="Otros";
    dashboard_comprador_button_registro ="Registro";
    dashboard_comprador_button_update ="Actualizar";
    dashboard_comprador_label_advice =  "*Al enviar su registro Usted esta aceptando nuestros Terminos y Condiciones así como lo establecido en nuestro ";
    dashboard_comprador_label_privacy = "Aviso de Privacidad."
    dashboard_comprador_label_privacy_url = "https://www.expomuebleinternacional.com.mx/es/privacity";
}

export default LocalizationEs;
