import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";

import Navbar from "./components/layout/Navbar";

//import Landing from "./components/layout/Landing";
//import Register from "./components/auth/Register";
//import Login from "./components/auth/Login";
//import PrivateRoute from "./components/private-route/PrivateRoute";
//import Dashboard from "./components/dashboard/Dashboard";

//import PasswordResetEmail from "./components/auth/PasswordResetEmail";
//import AccountActivation from "./components/auth/AccountActivation";
//import PasswordReset from "./components/auth/PasswordReset";

//import EmailActivationMessage from "./components/auth/EmailActivationMessage";

import Comprador from './components/dashboard/Comprador'
import CompradorEdit from './components/dashboard/CompradorEdit'
import CompradorLanding from './components/dashboard/CompradorLanding'

//import Estudiante from './components/auth/Estudiante'
//import Visitante from './components/auth/Visitante'
//import LandingGeneric from './components/auth/Landing'

import Analytics from 'react-router-ga';


import "./App.css";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = "./login";
  }
}
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
        <Analytics id="UA-52079326-3">
          <div className="App">
            <Navbar />
            <Route exact path="/comprador" component={Comprador} />
            <Route exact path="/comprador/:id" component={CompradorEdit} />
            <Route exact path="/compradorlanding" component={CompradorLanding}/>
          </div>
          </Analytics>
        </Router>
      </Provider>
    );
  }
}
export default App;
