import React, { Component } from "react";
import materialize from "materialize-css";
import 'react-flags-select/css/react-flags-select.css';
import logoEn from '../resources/BannerRegistroTecno-19-EN.jpg'
import logoEs from '../resources/BannerRegistroTecno-19-ES.jpg'
import mx from '../resources/mx.svg'
import us from '../resources/us.svg'
import detectBrowserLanguage from 'detect-browser-language'

class Navbar extends Component {


  constructor() {
    super();
    this.SetLanguage();
  }

  componentDidMount() {
    materialize.AutoInit();
  }

  getUrlVars() {
    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}

  SetLanguage() {

    var lang = this.getUrlVars()["lang"];
    if (lang !== undefined && (lang === "en" || lang === "es" || lang === "EN" || lang === "ES")) {
      localStorage.setItem("exmi_language", lang.toLowerCase());
    }
    else {
    const languagefromLocalStorage = localStorage.getItem("exmi_language");
    console.log(languagefromLocalStorage);

    if (languagefromLocalStorage  === null) {
      const countryCodeFromHeaders = detectBrowserLanguage();
      if (countryCodeFromHeaders.includes("en")) {
        localStorage.setItem("exmi_language", "en") 
      }
      else {
        localStorage.setItem("exmi_language", "es")
      }
    }
  }
}



  handleClickImage(e) {
    e.preventDefault();
    let countrycode = e.target.alt;
    localStorage.setItem("exmi_language", countrycode);
    var url = window.location.href.split('?')[0];
    window.location.href = url;
  }

  handleClickAnchor(e) {
    e.preventDefault();
    let countrycode = e.target.children[0].alt;
    localStorage.setItem("exmi_language", countrycode);
    var url = window.location.href.split('?')[0];
    window.location.href = url;
  }

  
  render() {
    return (
      <>
      <nav>
      <div className="nav-wrapper banner">
        <a href="#!" className="brand-logo left">
        { localStorage.getItem("exmi_language") === "es" ? 
          <img className="image-w-100 py-5 image-logo" id="logo" alt="EXMI" src={logoEs}/> :
          <img className="image-w-100 py-5 image-logo" id="logo" alt="EXMI" src={logoEn}/>
        }
        </a>
        <a href="#!" data-target="mobile-demo" className="sidenav-trigger">
          <i className="material-icons">menu</i></a>
        <ul className="right hide-on-med-and-down">
          <li><a href="#!" onClick={e => this.handleClickAnchor(e)}><img id="mx" title="Español" alt="es" className="image-flag" src={mx} onClick={e => this.handleClickImage(e)}/></a></li>
          <li><a href="#!" onClick={e => this.handleClickAnchor(e)}><img id="us" title="English" alt="en" className="image-flag" src={us} onClick={e => this.handleClickImage(e)}/></a></li>
        </ul>
      </div>
      </nav>

      <ul className="sidenav" id="mobile-demo">
        <li><a href="#!" onClick={e => this.handleClickAnchor(e)}><img id="mx_" title="Español" alt="es" className="image-flag" src={mx} onClick={e => this.handleClickImage(e)}/> Español</a></li>
        <li><a href="#!" onClick={e => this.handleClickAnchor(e)}><img id="us_" title="English" alt="en" className="image-flag" src={us} onClick={e => this.handleClickImage(e)}/> English</a></li>
      </ul>
      </>
    );
  }
}

export default Navbar;
